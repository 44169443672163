function numberToCurrency(number, currencyCode = 'EUR') {
    return new Intl.NumberFormat(
        navigator.language,
        { style: 'currency', currency: currencyCode },
    ).format(number);
}

export {
    numberToCurrency,
};

import { GET, POST } from './HTTPRequestMethod';
import { SERVER_ORIGIN } from '../config';

const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

function processResponse(response) {
    const contentType = response.headers.get('content-type');

    if (!contentType) {
        return Promise.resolve();
    }

    if (contentType.toLowerCase().indexOf('json') >= 0) {
        return response.json().catch(() => Promise.resolve());  // TODO probably improve the .catch
    }

    if (contentType.toLowerCase().indexOf('text') >= 0) {
        return response.text();
    }
}

function checkStatus(response) {
    const { status } = response;

    if (status >= 200 && status < 300) {
        return processResponse(response);
    }

    return processResponse(response).then(result => {
        console.error(result);
        window.ReactNativeWebView?.postMessage(JSON.stringify(result));

        return Promise.reject(result)
    });
}

function HTTPRequest({ url, path, origin, body, params, headers, method, accessToken }) {
    const theUrl = url || buildUrl(path, origin || SERVER_ORIGIN, params);

    const init = {
        method: method || (body ? POST : GET),
        body: body && (body === Object(body) ? JSON.stringify(body) : body),
        headers: accessToken
            ? { ...defaultHeaders, Authorization: accessToken, ...headers }
            : { ...defaultHeaders, ...headers },
    };

    if (init.method === GET) {
        console.log(`fetching ${theUrl}`);
    } else {
        console.log(`posting ${theUrl} ${init.body}`);
    }

    return fetch(theUrl, init)
        .then(checkStatus);
}

export function buildUrl(path, origin, params) {
    if (params) {
        const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`);

        return `${origin}/api${path}?${queryParams.join('&')}`;
    }

    return `${origin}/api${path}`;
}

export default HTTPRequest;

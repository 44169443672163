import { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText, Typography,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HTTPRequest from '../../../api/HTTPRequest';
import MaybeLoading from '../../../components/MaybeLoading';
import createEither from '../../../components/createEither';
import { SET_PAYLOAD, store } from '../../../store';

const StyledErrorText = styled(Typography)`
    color: ${({ theme }) => theme.palette.error.main};
`;

function OkButton({ deleteAccountHandler }) {
    return (
        <Button onClick={deleteAccountHandler} color="primary">
            Ok
        </Button>
    );
}

const MaybeOkButton = createEither({
    Left: ({ error }) => <StyledErrorText>{error}</StyledErrorText>,
    Right: OkButton,
    isRight: ({ error }) => !error,
});

function DeleteAccountButton({ paymentDetails }) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const history = useHistory();
    const { state, dispatch } = useContext(store);
    const { payload } = state;
    const { accessToken, merchant } = payload;

    const closeDialog = useCallback(() => {
        setDialogOpen(false);
        setLoading(false);
        setError(null);
    }, []);

    const deleteAccountHandler = useCallback(() => {
        setLoading(true);

        // TODO Delete specific payment method instead of current active one
        //      This button does not work correctly for multiple payment methods at the moment.

        HTTPRequest({
            accessToken,
            path: `/payments/forward/accounts/merchant/${merchant}/paymentmethod/action-delete`,
        })
            .then(() => {
                history.replace('/');

                // Reset the page
                dispatch({ type: SET_PAYLOAD, payload: {} });
                setTimeout(() => dispatch({ type: SET_PAYLOAD, payload }), 100);
            })
            .catch(e => {
                let message = 'Failed to delete payment account';

                if (e?.data?.indexOf('status is FROZEN') >= 0) {
                    message = 'Your payment account is frozen, contact support';
                }

                setError(message);
                setLoading(false);
            });
    }, [merchant, accessToken, history, payload]);

    return (
        <>
            <Button
                color="secondary"
                variant="outlined"
                size="small"
                startIcon={<DeleteForeverIcon />}
                onClick={() => setDialogOpen(true)}
                disabled={!paymentDetails}
            >
                Delete payment account
            </Button>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
            >
                <DialogTitle>Delete payment account?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Your payment information will be deleted. You will not be able to unlock
                        the Intelligent Fridge again until you add new payment information.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <MaybeLoading isLoading={isLoading} size="small">
                        <MaybeOkButton
                            error={error}
                            deleteAccountHandler={deleteAccountHandler}
                        />
                    </MaybeLoading>
                </DialogActions>
            </Dialog>
        </>
    );
}

DeleteAccountButton.propTypes = {
    paymentDetails: PropTypes.object.isRequired,
};

export default DeleteAccountButton;

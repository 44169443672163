import { useContext, useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import HTTPRequest from '../../api/HTTPRequest';
import { SET_ADYEN_PAYMENT_METHODS, SET_ERROR, store } from '../../store';
import MaybeLoading from '../../components/MaybeLoading';
import edenredLogo from '../../assets/images/edenred.svg';
import adyenLogo from '../../assets/images/adyen.svg';

const StyledList = styled(List)`
    margin-top: ${({ theme }) => theme.spacing(2)}px;
`;
const StyledListItem = styled(ListItem)`
    border-radius: 12px;
    background-color: #f7f8f9;
    border: 1px solid #e6e9eb;
    margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;
const StyledListItemAvatar = styled(ListItemAvatar)`
    min-width: 86px;
`;

const options = {
    EDENRED: {
        title: 'Edenred',
        getPath: () => '/new-payment-method/edenred',
        image: edenredLogo,
        imageWidth: 68,
    },
    ADYEN: {
        title: 'Card / Bank',
        getPath: () => '/new-payment-method/adyen',
        image: adyenLogo,
        imageWidth: 72,
    },
};

function InternalLinkItem({ children, path }) {
    return (
        <StyledListItem button component={Link} to={{ pathname: path, state: { withBack: true } }}>
            {children}
        </StyledListItem>
    );
}

function PaymentOptions({ paymentOptions, merchant, languageCode, accessToken }) {
    if (!paymentOptions || paymentOptions.length < 1) {
        return (
            <Typography color="error">No payment options found.</Typography>
        );
    }

    return paymentOptions.map(({ paymentProcessor }, index) => {
        const option = options[paymentProcessor];

        if (!option) {
            return <Fragment key={index} />;
        }

        return (
            <InternalLinkItem
                key={paymentProcessor}
                path={option.getPath(merchant, languageCode)}
                accessToken={accessToken}
            >
                <StyledListItemAvatar>
                    <Avatar
                        variant='square'
                        alt={option.title}
                        src={option.image}
                        style={{ width: `${option.imageWidth}px` }}
                    />
                </StyledListItemAvatar>
                <ListItemText primary={option.title} />
            </InternalLinkItem>
        );
    });
}

function NewPaymentMethod({ history }) {
    const [isLoading, setLoading] = useState(true);
    const [paymentOptions, setPaymentOptions] = useState([]);

    const { state, dispatch } = useContext(store);
    const { payload } = state;
    const { accessToken, merchant, languageCode } = payload;

    useEffect(() => {
        setLoading(true);

        HTTPRequest({
            accessToken,
            path: `/payments/forward/payments/merchant/${merchant}/methods`,
        })
            .then(({ data }) => {
                data.forEach(option => {
                    if (option.paymentProcessor === 'ADYEN') {
                        dispatch({
                            type: SET_ADYEN_PAYMENT_METHODS,
                            payload: option.paymentMethods,
                        });
                    }
                });

                if (data.length === 1) {
                    history.push(options[data[0].paymentProcessor].getPath(merchant, languageCode));
                } else {
                    setPaymentOptions(data);
                    setLoading(false);
                }
            })
            .catch(() => {
                dispatch({ type: SET_ERROR, payload: true });
            });
    }, [accessToken, merchant, languageCode]);

    return (
        <MaybeLoading isLoading={isLoading}>
            <Typography variant="h5">Select payment option</Typography>
            <StyledList component="nav">
                <PaymentOptions
                    paymentOptions={paymentOptions}
                    languageCode={languageCode}
                    merchant={merchant}
                    accessToken={accessToken}
                />
            </StyledList>
        </MaybeLoading>
    );
}

export default NewPaymentMethod;
import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isString } from 'lodash';
import { Typography } from '@material-ui/core';
import { store } from '../store';

const StyledErrorContainer = styled.div`
    border-radius: 12px;
    background-color: #f7f8f9;
    border: 1px solid #e6e9eb;
`;
const StyledErrorMessage = styled(Typography)`
    margin: ${({ theme }) => theme.spacing(1, 2)};
    color: ${({ theme }) => theme.palette.error.main};
`;

function ErrorWrapper({ children }) {
    const { state } = useContext(store);
    const { error } = state;

    if (!error) {
        return children;
    }

    const defaultMessage = 'Something went wrong, could not find payment information. ' +
        'Please contact support if this issue persists.';
    const message = isString(error) ? error : defaultMessage;

    return (
        <StyledErrorContainer>
            <StyledErrorMessage>
                {message}
            </StyledErrorMessage>
        </StyledErrorContainer>
    );
}

ErrorWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorWrapper;
import { createContext, useReducer } from 'react';

const initialState = {
    error: false,
    payload: {},
    recurringPaymentDetails: null,
};

export const SET_ERROR = 'SET_ERROR';
export const SET_PAYLOAD = 'SET_PAYLOAD';
export const SET_RECURRING_PAYMENT_DETAILS = 'SET_RECURRING_PAYMENT_DETAILS';
export const SET_ADYEN_PAYMENT_METHODS = 'SET_ADYEN_PAYMENT_METHODS';

export const store = createContext(initialState);

const { Provider } = store;

function reducer(state, action) {
    switch (action.type) {
        case SET_ERROR:
            return { ...state, error: action.payload }
        case SET_PAYLOAD:
            return { ...state, payload: action.payload };
        case SET_RECURRING_PAYMENT_DETAILS:
            return { ...state, recurringPaymentDetails: action.payload };
        case SET_ADYEN_PAYMENT_METHODS:
            return { ...state, adyenPaymentMethods: action.payload };
        default:
            throw new Error(`Action with type ${action.type} unknown.`);
    }
}

export function StateProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchWithLog = props => {
        console.log(props.type, props.payload);
        dispatch(props);
    }

    return (
        <Provider value={{ state, dispatch: dispatchWithLog }}>{children}</Provider>
    );
}

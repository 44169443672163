import styled from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import ErrorWrapper from './ErrorWrapper';
import StartupLoader from './StartupLoader';
import Routes from '../views/Routes';

const Root = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
`;
const Content = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: ${props => props.theme.spacing(3)}px;
`;

function Layout() {
    return (
        <Root>
            <CssBaseline />
            <BrowserRouter>
                <Content>
                    <ErrorWrapper>
                        <StartupLoader>
                            <Routes />
                        </StartupLoader>
                    </ErrorWrapper>
                </Content>
            </BrowserRouter>
        </Root>
    );
}

export default Layout;

import PropTypes from 'prop-types';

function createEither({ Left, Right, isRight }) {

    return function Either(props) {

        if (isRight(props)) {
            return <Right {...props} />;
        }

        return <Left {...props} />;
    };
}

createEither.propTypes = {
    Left: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
    Right: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.func,
    ]).isRequired,
    isRight: PropTypes.func.isRequired,
};

export default createEither;

import { Switch, Route, Redirect } from 'react-router-dom';
import RedirectResult from './redirect/RedirectResult';
import NewPaymentMethod from './newpaymentmethod/NewPaymentMethod';
import PaymentDetails from './storedpaymentdetails/PaymentDetails';
import AdyenDropin from './newpaymentmethod/components/AdyenDropin';
import EdenredLogin from './newpaymentmethod/components/EdenredLogin';

function Routes() {
    return (
        <Switch>
            <Redirect exact path="/" to="/stored-payment-details" />
            <Route exact path="/new-payment-method" component={NewPaymentMethod} />
            <Route path="/new-payment-method/adyen" component={AdyenDropin} />
            <Route path="/new-payment-method/edenred" component={EdenredLogin} />
            <Route path="/stored-payment-details" component={PaymentDetails} />
            {/* <Route path="/account/balance" component={AccountBalance} /> */}
            <Route path="/redirect" component={RedirectResult} />
        </Switch>
    );
}

export default Routes;

import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import HTTPRequest from '../api/HTTPRequest';
import { SET_ERROR, SET_PAYLOAD, SET_RECURRING_PAYMENT_DETAILS, store } from '../store';
import CenteredLoader from './CenteredLoader';

function StartupLoader({ children }) {
    const [isLoading, setLoading] = useState(true);

    const history = useHistory();
    const { pathname } = useLocation();
    const { state, dispatch } = useContext(store);
    const { payload } = state;
    const { accessToken, merchant } = payload || {};
    const setPayload = value => dispatch({ type: SET_PAYLOAD, payload: value });

    const redirectPage = useCallback(() => {
        HTTPRequest({
            accessToken,
            path: `/payments/forward/accounts/merchant/${merchant}/authorised`,
        })
            .then(({ data }) => {
                if (data === true) {
                    // if authorised on our server, the payment registration is probably still pending.
                    history.replace('/redirect?resultCode=Authorised');
                } else {
                    history.replace('/new-payment-method');
                }

                setLoading(false);
            })
            .catch(error => {
                const frozenOrClosed = error?.data?.includes('frozen') || error?.data?.includes('closed');
                const incorrectOrIncomplete = error?.data?.includes('incorrect or incomplete');
                const unauthorised = error?.data?.includes('unauthorised active recurring payment methods found');
                const edenredRefreshFailed = error?.data?.includes('/connect/token');

                if (frozenOrClosed || unauthorised || incorrectOrIncomplete || edenredRefreshFailed) {
                    if (unauthorised && error.data.includes('EDENRED')) {
                        history.replace('/new-payment-method/edenred');
                    } else if (unauthorised && error.data.includes('ADYEN')) {
                        history.replace('/new-payment-method/adyen');
                    } else {
                        history.replace('/new-payment-method');
                    }
                    setLoading(false);
                } else {
                    dispatch({ type: SET_ERROR, payload: true });
                }
            });
    }, [accessToken, merchant]);

    useEffect(() => {
        window.ReactNativeWebView?.postMessage(`Payload ${JSON.stringify(window.payload)}`);
        window.setPayload = setPayload; // used by fridge-mobile-app (android)
        window.payload && setPayload(window.payload);  // when js is injected before useEffect is called (ios)
    }, []);

    useEffect(() => {
        if (!accessToken || !merchant) return;

        HTTPRequest({
            accessToken,
            path: `/payments/forward/accounts/merchant/${merchant}/stored-payment-methods`,
        })
            .then(({ data }) => {
                if (
                    data?.length === 0 || (  // No payment account known
                        data?.length === 1 &&
                        data[0].paymentProcessor === 'ADYEN' &&
                        data[0].paymentDetails?.details?.length === 0  // initially after ideal registration for example
                    )
                ) {
                    redirectPage();
                } else {
                    dispatch({ type: SET_RECURRING_PAYMENT_DETAILS, payload: data });
                    setLoading(false);
                }
            })
            .catch(error => {
                const edenredRefreshFailed = error?.data?.includes('/connect/token');
                const edenredSessionExpired = error?.data?.includes('Edenred session expired');

                if (edenredRefreshFailed || edenredSessionExpired) {
                    redirectPage();
                } else {
                    dispatch({ type: SET_ERROR, payload: true });
                }
            });
    }, [accessToken, merchant, redirectPage]);

    if (isLoading && pathname !== '/redirect') {  // /redirect is the only public path
        return <CenteredLoader />;
    }

    return children;
}

StartupLoader.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StartupLoader;

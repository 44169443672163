import { env } from '../env';

const {
    REACT_APP_SERVER_ORIGIN: SERVER_ORIGIN,
    REACT_APP_ENVIRONMENT: ENVIRONMENT,
} = env;

export {
    SERVER_ORIGIN,
    ENVIRONMENT,
};

import { useContext, useEffect } from 'react'
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import DeleteAccountButton from './components/DeleteAccountButton';
import { SET_ERROR, store } from '../../store';
import { numberToCurrency } from '../../util/functions';

const StyledInformationContainer = styled.div`
    border-radius: 12px;
    background-color: #f7f8f9;
    border: 1px solid #e6e9eb;
    padding: ${({ theme }) => theme.spacing(2, 2, 0.5, 2)};
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
const StyledInformationLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;
const StyledTitle = styled(Typography)`
    font-size: 22px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
const StyledLabelText = styled(Typography)`
    font-size: 16px;
`;
const StyledInfo = styled(Typography)`
    font-size: 16px;
    font-weight: 200;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: ${({ theme }) => theme.spacing(3, 0, 1.5, 0)};
`;

function InformationLine({ label, info }) {
    return (
        <StyledInformationLine>
            <StyledLabelText>{label}</StyledLabelText>
            <StyledInfo>{info ?? '?'}</StyledInfo>
        </StyledInformationLine>
    );
}

function CardInformation({ recurringDetail }) {
    const { card, variant } = recurringDetail;
    const { expiryYear, expiryMonth, holderName, number } = card;

    return (
        <StyledInformationContainer>
            <StyledTitle>Card information</StyledTitle>
            <InformationLine label="Type" info={variant} />
            <InformationLine label="Expiry date" info={`${expiryMonth}/${expiryYear}`} />
            <InformationLine label="Holder name" info={holderName} />
            <InformationLine label="Last 4 digits" info={number} />
            <StyledButtonContainer>
                <DeleteAccountButton paymentDetails={recurringDetail} />
            </StyledButtonContainer>
        </StyledInformationContainer>
    );
}

function TwintInformation( { recurringDetail }) {
    const { creationDate } = recurringDetail;
    return (
        <StyledInformationContainer>
            <StyledTitle>Payment information</StyledTitle>
            <InformationLine label="method" info="TWINT" />
            <InformationLine label="registration date" info={creationDate} />
            <StyledButtonContainer>
                <DeleteAccountButton paymentDetails={recurringDetail} />
            </StyledButtonContainer>
        </StyledInformationContainer>
    );
}

function BankInformation({ recurringDetail }) {
    const { bank } = recurringDetail;
    const { iban, ownerName, bankName, countryCode, bic } = bank;

    return (
        <StyledInformationContainer>
            <StyledTitle>Payment information</StyledTitle>
            <InformationLine label="iban" info={iban} />
            <InformationLine label="owner" info={ownerName} />
            <InformationLine label="bank" info={bankName} />
            <InformationLine label="country" info={countryCode} />
            <InformationLine label="bic" info={bic} />
            <StyledButtonContainer>
                <DeleteAccountButton paymentDetails={recurringDetail} />
            </StyledButtonContainer>
        </StyledInformationContainer>
    );
}

function EdenredInformation({ paymentDetails }) {
    const { username, balance, currency } = paymentDetails;
    const amount = numberToCurrency(balance / 100, currency || 'EUR');

    return (
        <StyledInformationContainer>
            <StyledTitle>Edenred account</StyledTitle>
            <InformationLine label="Username" info={username} />
            <InformationLine label="Balance" info={amount} />
            <StyledButtonContainer>
                <DeleteAccountButton paymentDetails={paymentDetails} />
            </StyledButtonContainer>
        </StyledInformationContainer>
    );
}

function PaymentDetails() {
    const { state, dispatch } = useContext(store);
    const { recurringPaymentDetails } = state;

    useEffect(() => {
        !recurringPaymentDetails && dispatch({ type: SET_ERROR, payload: true });
    }, [recurringPaymentDetails]);

    return (recurringPaymentDetails || []).map(({ paymentProcessor, paymentDetails }, idx1) => {
        switch (paymentProcessor) {
            case 'ADYEN':
                return paymentDetails.details.map((detail, idx2) => {
                    switch (detail.RecurringDetail.variant) {
                        case 'twint':
                            return (
                                <TwintInformation
                                    key={`${idx1}-${idx2}`}
                                    recurringDetail={detail.RecurringDetail}
                                />
                            );
                        case 'ideal':
                        case 'sepadirectdebit':
                        case 'directEbanking':

                            return (
                                <BankInformation
                                    key={`${idx1}-${idx2}`}
                                    recurringDetail={detail.RecurringDetail}
                                />
                            );
                        default:
                            return (
                                <CardInformation
                                    key={`${idx1}-${idx2}`}
                                    recurringDetail={detail.RecurringDetail}
                                />
                            );
                    }
                });
            case 'EDENRED':
                return (
                    <EdenredInformation key={idx1} paymentDetails={paymentDetails} />
                );
            default:
                return (
                    <Typography color="error">
                        {`Unknown paymentProcessor ${paymentProcessor}`}
                    </Typography>
                );
        }
    });
}

export default PaymentDetails;
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    ${({ absolute }) => absolute && css`
        position: absolute;
        top: 50%;
        left: 50%;
        width: initial;
        height: initial;
    `}
`;

const StyledTextContainer = styled.div`
    margin-top: 20px;
`;

function CenteredLoader({ color, size, absolute, text, className }) {
    return (
        <StyledContainer absolute={absolute} className={className}>
            <CircularProgress color={color} size={size === 'small' ? 26 : 40} />
            {
                text &&
                <StyledTextContainer>
                    <Typography variant="body1">
                        {text}
                    </Typography>
                </StyledTextContainer>
            }
        </StyledContainer>
    );
}

CenteredLoader.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
    size: PropTypes.oneOf(['small', 'large']),
    absolute: PropTypes.bool,
    text: PropTypes.string,
};

CenteredLoader.defaultProps = {
    color: 'primary',
    size: 'large',
    absolute: false,
    text: null,
};

export default CenteredLoader;


import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import AdyenCheckout from '@adyen/adyen-web';
import { ENVIRONMENT } from '../../config';
import { store } from '../../store';

import '@adyen/adyen-web/dist/adyen.css';

function RedirectResult() {
    const dropinEl = useRef();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const resultCode = params.get('resultCode');
    const { state } = useContext(store);
    const { payload } = state;
    const languageCode = payload?.languageCode;

    useEffect(() => (
        new AdyenCheckout({
            paymentMethodsResponse: undefined,
            locale: languageCode || 'en-GB',
            environment: ENVIRONMENT,
            onSubmit: () => {},
            paymentMethodsConfiguration: {
                card: { // Example optional configuration for Cards
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: false,
                    name: 'Card',
                },
            },
        }).then(checkout => {

            const dropin = checkout
                .create('dropin', {
                    showPayButton: false,
                    onReady:() => {
                        switch (resultCode) {
                            case 'Authorised':
                            case 'Pending':
                            case 'Received':
                                dropin.setStatus('success', { message: 'Registration successful' });
                                break;
                            case 'Error':
                            case 'Refused':
                                dropin.setStatus('error', { message: 'Registration failed' });
                                break;
                            case 'Cancelled':
                                dropin.setStatus('error', { message: 'Registration cancelled' });
                                break;
                            default:
                                break;
                        }

                    },
                })
                .mount(dropinEl.current);
        })
    ), [languageCode, resultCode]);

    return (
        <div ref={dropinEl} />
    );
}

export default RedirectResult;
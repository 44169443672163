import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import CenteredLoader from './CenteredLoader';

const StyledContainer = styled.div`
    position: relative;
    height: 100%;
`;
const StyledCenteredLoader = styled(({ isLoading, ...rest }) => <CenteredLoader {...rest} />)`
    position: absolute;
    visibility: hidden;
    
    ${({ isLoading }) => isLoading && css`
        visibility: initial;
    `}
`;
const StyledContentContainer = styled.div`
    visibility: inherit;
    height: 100%;
    
    ${({ isLoading }) => isLoading && css`
        visibility: hidden;
    `}
`;

function MaybeLoading({ isLoading, size, className, children }) {
    return (
        <StyledContainer className={className}>
            <StyledCenteredLoader isLoading={isLoading} color="primary" size={size} />
            <StyledContentContainer isLoading={isLoading}>
                {children}
            </StyledContentContainer>
        </StyledContainer>
    );
}

MaybeLoading.propTypes = {
    size: PropTypes.oneOf(['small', 'large']),
    isLoading: PropTypes.bool.isRequired,
};

MaybeLoading.defaultProps = {
    size: 'large',
};

export default MaybeLoading;

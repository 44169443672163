import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import defaultTheme from './themes/default';
import { StateProvider } from './store';
import Layout from './components/Layout';

const theme = createMuiTheme(defaultTheme);

function App() {
    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <StateProvider>
                        <Layout />
                    </StateProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
}

export default App;

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import AdyenCheckout from '@adyen/adyen-web';
import HTTPRequest from '../../../api/HTTPRequest';
import { ENVIRONMENT } from '../../../config';
import createEither from '../../../components/createEither';
import MaybeLoading from '../../../components/MaybeLoading';
import { SET_ERROR, store } from '../../../store';

import '@adyen/adyen-web/dist/adyen.css';

// const ADYEN_SDK = `https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/${DROPIN_SDK_VERSION}`;
// const ADYEN_CSS = `${ADYEN_SDK}/adyen.css`;
// const ADYEN_JS = `${ADYEN_SDK}/adyen.js`;

const StyledDropinContainer = styled.div`
    padding: 8px;
`;
const StyledButtonContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledMessage = styled(Typography)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    color: ${({ theme }) => theme.palette.success.main};
    
    ${({ type }) => type === 'error' && css`
        color: ${({ theme }) => theme.palette.error.main};
    `}
`;

function Message({ message }) {
    if (!message) {
        return null;
    }

    return (
        <StyledMessage type={message.type}>
            {message.message}
        </StyledMessage>
    );
}

function SubmitButton({ dropin, isButtonLoading }) {
    return (
        <StyledButtonContainer>
            <MaybeLoading isLoading={isButtonLoading} size="small">
                <Button color="primary" size="large" onClick={dropin.submit}>
                    submit
                </Button>
            </MaybeLoading>
        </StyledButtonContainer>
    );
}

const MaybeSubmitButton = createEither({
    Left: Message,
    Right: SubmitButton,
    isRight: ({ message, dropin }) => !message && dropin,
});

function AdyenDropin({ history }) {
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const [dropin, setDropin] = useState(null);
    const [isButtonLoading, setButtonLoading] = useState(false);

    const dropinEl = useRef(null);
    const { dispatch, state } = useContext(store);
    const { payload, adyenPaymentMethods } = state;
    const { accessToken, languageCode, merchant } = payload || {};

    const registerCard = useCallback(({ data }, dropinRef) => {
        setButtonLoading(true);

        HTTPRequest({
            accessToken,
            path: `/payments/forward/payments/merchant/${merchant}/register`,
            body: {
                dropinGeneratedData: data,
                origin: window.location.origin,
            },
        })
            .then(response => {
                if (response.refusalReason) {
                    dropinRef.setStatus('error', { message: response.refusalReason });
                    setMessage({ type: 'error', message: response.refusalReason });
                } else if (response.resultCode?.toUpperCase() === 'AUTHORISED') {
                    dropinRef.setStatus('success', { message: 'Payment information saved' });
                    setMessage({ type: 'success', message: 'Payment information saved.' });
                } else {
                    dropinRef.handleAction(response);
                }
            })
            .catch(error => {
                if (error?.data?.indexOf('already has a registered') >= 0) {
                    return setMessage({
                        type: 'error',
                        message: 'This account already has a registered payment method.',
                    });
                }

                setMessage({
                    type: 'error',
                    message: 'Something went wrong, please try again later.',
                });
            })
            .finally(() => setButtonLoading(false));
    }, [accessToken, merchant]);

    useEffect(() => {
        if (!accessToken || !adyenPaymentMethods) return;

        HTTPRequest({
            accessToken,
            path: `/payments/forward/merchant/${merchant}/clientkey`,
        })
            .then(({ data }) => {
                const { oneClickPaymentMethods } = adyenPaymentMethods;
                let paymentMethodsResponse = adyenPaymentMethods;

                if (oneClickPaymentMethods) {
                    paymentMethodsResponse = { oneClickPaymentMethods };

                    setMessage({
                        type: 'success',
                        message: 'You\'ve already registered a payment method.',
                    });
                }

                return new AdyenCheckout({
                    paymentMethodsResponse,
                    clientKey: data,
                    locale: languageCode || 'en-GB',
                    environment: ENVIRONMENT,
                    onSubmit: registerCard,
                    onError: (error) => {
                        window.ReactNativeWebView?.postMessage(JSON.stringify(error));
                        console.log(error);
                    },
                    paymentMethodsConfiguration: {
                        card: {
                            brands: ['bcmc', 'mc', 'visa', 'amex', 'cup', 'diners', 'discover', 'jcb'],
                            hasHolderName: true,
                            holderNameRequired: true,
                            enableStoreDetails: false,
                            name: 'Card',
                            billingAddressRequired: true,
                        },
                        bcmc: {
                            brands: ['bcmc', 'visa'],
                            hasHolderName: true,
                            holderNameRequired: true,
                            enableStoreDetails: false,
                            name: 'Bancontact card',
                        },
                    },
                });
            })
            .then(checkout => {
                const createdDropin = checkout
                    .create('dropin', {
                        showPayButton: false,
                        openFirstPaymentMethod: false,
                    })
                    .mount(dropinEl.current);

                setLoading(false);
                setDropin(createdDropin);
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: SET_ERROR, payload: true });
            });
    }, [accessToken, languageCode, merchant, adyenPaymentMethods, registerCard]);

    useEffect(() => {  // Shouldn't happen, but redirect to fetch the methods if needed
        !adyenPaymentMethods && history.replace('/new-payment-method');
    }, [adyenPaymentMethods]);

    return (
        <MaybeLoading isLoading={isLoading}>
            <StyledContentContainer>
                <StyledDropinContainer ref={dropinEl} />
                <MaybeSubmitButton
                    dropin={dropin}
                    message={message}
                    isButtonLoading={isButtonLoading}
                />
            </StyledContentContainer>
        </MaybeLoading>
    );
}

export default AdyenDropin;
import { useContext, useEffect } from 'react';
import { SET_ERROR, store } from '../../../store';
import HTTPRequest from '../../../api/HTTPRequest';
import CenteredLoader from '../../../components/CenteredLoader';

function EdenredLogin() {
    const { state, dispatch } = useContext(store);
    const { payload } = state;
    const { accessToken, merchant, languageCode } = payload;

    useEffect(() => {
        HTTPRequest({
            accessToken,
            path: `/payments/forward/edenred/merchant/${merchant}/authentication/login?languageCode=${languageCode}`,
        })
            .then(({ data }) => {
                window.location.replace(data);  // iFrame does not seem to work in
            })
            .catch(() => {
                dispatch({
                    type: SET_ERROR,
                    payload: 'Could not reach Edenred login page. ' +
                        'Please contact support if this issue persists.',
                });
            });
    }, [accessToken, merchant, languageCode]);

    // iFrame would be nicer, but we can't get that to work inside the WebView

    return <CenteredLoader />;
}

export default EdenredLogin;
